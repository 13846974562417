// src/App.js

import React from 'react';
import Navbar from './components/NavExample';
import './App.css';
import {Button} from "react-bootstrap";
import Footer from "./components/Footer";

function App() {
    return (
            <div className="App">
                <Navbar/>
                <header className="App-header">
                    <h1 className="h">Coming Soon!</h1>
                    <p className="para">I am Currently working on my new website<br/>I will be here soon, click on "Notify Me!" to be
                        ready for the Grand Day!</p>

                    <Button variant="outline-light" className="btn">Notify Me</Button>
                </header>
                <Footer/>
            </div>
    );
}

export default App;
